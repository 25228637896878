import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Snackbar,
  Alert,
} from '@mui/material'

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const FeedbackForm = ({ articleId, onClose, showSnackbar }) => {
  const [feedbackText, setFeedbackText] = useState('')
  const [userEmail, setUserEmail] = useState('')
  // const [snackbarOpen, setSnackbarOpen] = useState(false)
  // const handleSnackbarClose = (event, reason) => {
  //   if (reason === 'clickaway') return
  //   setSnackbarOpen(false)
  // }
  const handleSubmit = async (event) => {
    event.preventDefault()
    const feedback = {
      articleId,
      feedbackText,
      userEmail,
    }

    try {
      await axios
        .post(`${baseUrl}/api/v1/feedbacks/`, feedback, {
          withCredentials: true,
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
          }
          console.log(error.config)
          alert('Failed to submit feedback')
        })

      setFeedbackText('')
      setUserEmail('')
      onClose()
      showSnackbar('Feedback submitted successfully 😄', 'success') 
      // setSnackbarOpen(true)  <-- we can't directly render Snackbar by mentioning it in FeedbackForm component, why??
      //       <Modal /> present in <ArticleCard /> , and inside Modal we have <Article />, and in Article we have <FeedbackForm /> which is try to render <Snackbar />
      // The modal's rendering behavior could interfere with how the Snackbar is displayed. Snackbars usually render on the top of the DOM tree, and being inside a modal might restrict its visibility due to the modal's context and z-index behavior.
    } catch (error) {
      console.error('Error submitting feedback:', error)
      showSnackbar('Failed to submit feedback 😞', 'error') // Show error Snackbar
    }
  }

  return (
    <>
      <Container maxWidth='sm'>
        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}
        >
          <Typography variant='h5' component='h2' gutterBottom>
            Submit Feedback
          </Typography>
          <TextField
            id='feedbackText'
            label='Report any broken links, or any misleading data in this article'
            variant='outlined'
            multiline
            rows={4}
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            required
          />
          <TextField
            id='userEmail'
            label='Email'
            type='email'
            variant='outlined'
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
          />
          <Button variant='contained' color='primary' type='submit'>
            Submit
          </Button>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            Close
          </Button>
        </Box>
      </Container>
      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity='success'
          sx={{ width: '100%' }}
        >
          Feedback submitted successfully 😄
        </Alert>
      </Snackbar> */}
    </>
  )
}

export default FeedbackForm;
