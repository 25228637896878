import ReactGA from 'react-ga4'
import React, { useState, useEffect, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Pagination from './Pagination'
import ArticleCard from './ArticleCard'
import GuideCard from './guides/GuideCard'
import DecisionCard from './decisions/DecisionCard'
import usePageTracking from '../services/usePageTracking'
const baseUrl = process.env.REACT_APP_BACKEND_URL

axios.interceptors.request.use((config) => {
  // Log the request details to GA4
  ReactGA.send({
    hitType: 'event',
    eventCategory: 'Network Request',
    eventAction: 'Request Sent',
    eventLabel: config.url,
  })
  return config
})

const SideBar = ({
  selectedCountry,
  setSelectedCountry,
  selectedLawShortName,
  setSelectedLawShortName,
  searchQuery,
  setSearchQuery,
  handleSearch,
  handleReset,
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [countries, setCountries] = useState([])
  const [laws, setLaws] = useState([])

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await axios.get(`${baseUrl}/api/v1/countries/with-laws`,
        {
          withCredentials: true,
        })
      setCountries(response?.data)
    }
    fetchCountries()
  }, [])

  // Update laws when a country is selected
  useEffect(() => {
    if (countries.length > 0 && selectedCountry) {
      const selectedCountryData = countries.find(
        (country) =>
          country.countryName.toLowerCase() === selectedCountry.toLowerCase()
      )
      setLaws(selectedCountryData?.laws || [])
    }
  }, [selectedCountry, countries])

  // Get the lawFullName for the selectedLawShortName
const selectedLawFullName =
  laws.find(
    (law) =>
      law.lawShortName.toLowerCase() === selectedLawShortName.toLowerCase()
  )?.lawFullName || ''
  return (
    <Box
      sx={{
        // marginTop: 4,
        margin: 2,
        width: isSmallScreen ? '100%' : '300px',
        padding: 2,
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
        borderRight: '1px solid #ccc',
      }}
    >
      <Typography variant='h4'>Filters</Typography>
      <FormControl fullWidth margin='normal'>
        <InputLabel shrink={selectedCountry !== ''}>Select Country</InputLabel>
        <Select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          label='Select Country'
        >
          <MenuItem value=''>Select Country</MenuItem>
          {countries.map((country) => (
            <MenuItem key={country.id} value={country.countryName}>
              {country.countryName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin='normal'>
        <InputLabel shrink={selectedLawShortName !== ''}>Select Law</InputLabel>
        <Select
          value={
            selectedLawShortName
            // countries
            //   ?.filter(
            //     (country) =>
            //       country?.countryName.toLowerCase() ===
            //       selectedCountry.toLowerCase()
            //   )[0]
            //   ?.laws?.filter(
            //     (law) =>
            //       law?.lawShortName.toLowerCase() ===
            //       selectedLawShortName.toLowerCase()
            //   )[0]?.lawFullName
          }
          onChange={(e) => setSelectedLawShortName(e.target.value)}
          label='Select Law'
          disabled={!selectedCountry || laws.length === 0}
          renderValue={() => selectedLawFullName} // Display lawFullName instead of lawShortName
        >
          <MenuItem value=''>Select Law</MenuItem>
          {laws.map((law) => (
            <MenuItem key={law.id} value={law.lawShortName}>
              {law.lawFullName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label='Enter Search Query'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        margin='normal'
      />
      <Box display='flex' justifyContent='space-between' mt={2}>
        <Button
          variant='contained'
          onClick={handleSearch}
          sx={{
            color: '#fff',
            bgcolor: '#232536',
            mr: 2,
            fontWeight: 500,
            ':hover': { color: '#232536', bgcolor: '#ffcf51' },
          }}
        >
          Search
        </Button>
        <Button
          variant='outlined'
          onClick={handleReset}
          sx={{
            color: '#232536',
            bgcolor: '#fff',
            borderColor: '#232536',
            fontWeight: 500,
            ':hover': {
              color: '#232536',
              bgcolor: '#ffcf51',
              borderColor: '#232536',
            },
          }}
        >
          Reset
        </Button>
      </Box>
    </Box>
  )
}

const GlobalSearch = () => {
  usePageTracking()
  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: '/search',
  //     title: 'Global Search',
  //   })
  // })
  const [searchParams] = useSearchParams()
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedLawShortName, setSelectedLawShortName] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [articlePages, setArticlePages] = useState({
    results: [],
    totalPages: 0,
  })
  const [decisionPages, setDecisionPages] = useState({
    results: [],
    totalPages: 0,
  })
  const [guideContentPages, setGuideContentPages] = useState({
    results: [],
    totalPages: 0,
  })
  // below 3 vars  used in homepage
  const [currentArticlePage, setCurrentArticlePage] = useState(1)
  const [currentDecisionPage, setCurrentDecisionPage] = useState(1)
  const [currentGuideContentPage, setCurrentGuideContentPage] = useState(1)
  //  above 3 vars used in homepage
  const [isFetching, setIsFetching] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const fetchDefaultData = useCallback(async () => {
    //   searchParams, setSearchParams <-- state variable
    //   searchParams, useSearchParams <-- fetching query params

    //   setSearchParams({ selectedCountry, selectedLawShortName, searchQuery });

    try {
      setIsFetching(true)
      const response = await axios.get(`${baseUrl}/api/v1/global-search/`, {
        params: {
          country: selectedCountry,
          lawShortName: selectedLawShortName,
          query: searchQuery,
          articlePage: 1,
          decisionPage: 1,
          guidePage: 1,
        },
	withCredentials: true
      })

      setArticlePages(response.data.articlePages)
      setDecisionPages(response.data.decisionPages)
      setGuideContentPages(response.data.guideContentPages)

      setIsFetching(false)
      setSnackbarOpen(true)
    } catch (error) {
      console.error('Error fetching default search results:', error)
    } finally {
      setIsFetching(false)
      setSnackbarOpen(false)
    }
  }, [])

  useEffect(() => {
    const country = searchParams.get('country')
    const law = searchParams.get('law')
    const query = searchParams.get('query')
    if (country) setSelectedCountry(country)
    if (law) setSelectedLawShortName(law)
    if (query) setSearchQuery(query)
    if (country && law) fetchDefaultData()
  }, [searchParams, fetchDefaultData])

  const fetchSearchResults = useCallback(
    async (articlePage = 1, decisionPage = 1, guidePage = 1) => {
      if (selectedCountry && selectedLawShortName) {
        //   searchParams, setSearchParams <-- state variable
        //   searchParams, useSearchParams <-- fetching query params

        //   setSearchParams({ selectedCountry, selectedLawShortName, searchQuery });

        try {
          const response = await axios.get(`${baseUrl}/api/v1/global-search/`, {
            params: {
              country: selectedCountry,
              lawShortName: selectedLawShortName,
              query: searchQuery,
              articlePage,
              decisionPage,
              guidePage,
            },
	    withCredentials: true
          })

          setArticlePages(response.data.articlePages)
          setDecisionPages(response.data.decisionPages)
          setGuideContentPages(response.data.guideContentPages)

          setIsFetching(false)
          setSnackbarOpen(true)
        } catch (error) {
          console.error('Error fetching search results:', error)
        } finally {
          setIsFetching(false)
          setSnackbarOpen(false)
        }
      }
    },
    [
      // searchParams,
      selectedCountry,
      selectedLawShortName,
      searchQuery,
      // currentArticlePage,
      // currentDecisionPage,
      // currentGuideContentPage,
    ]
  )

  useEffect(() => {
    if (isFetching) {
      fetchSearchResults()
    }
  }, [fetchSearchResults, isFetching])

  const handleSearch = () => {
    if (selectedCountry && selectedLawShortName) {
      fetchSearchResults()
    } else fetchDefaultData()
  }

  const handleReset = useCallback(() => {
    setSelectedCountry('')
    setSelectedLawShortName('')
    setSearchQuery('')
    setArticlePages({ results: [], totalPages: 0 })
    setDecisionPages({ results: [], totalPages: 0 })
    setGuideContentPages({ results: [], totalPages: 0 })
    setCurrentArticlePage(1)
    setCurrentDecisionPage(1)
    setCurrentGuideContentPage(1)
  })

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  return (
    <Container>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={4} md={3}> */}
        {/* <Grid item xs={12} sm={12} md={4} lg={3}> */}
        <Grid item xs={12} sm={6} md={4}>
          {/* <Box display="flex" sx={{ marginTop: 4 }}> */}
          <SideBar
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            selectedLawShortName={selectedLawShortName}
            setSelectedLawShortName={setSelectedLawShortName}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
        </Grid>
        {/* <Grid item xs={12} sm={8} md={9}> */}
        {/* <Grid item xs={12} sm={12} md={8} lg={9}> */}
        <Grid item xs={12} sm={6} md={8} mt={2} mb={2}>
          <Container>
            <Box mt={2} mb={2}>
              <Typography variant='h4' gutterBottom>
                Search Results
              </Typography>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  // aria-controls="article-content" id="article-header"
                >
                  <Typography variant='h6'>Articles</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {articlePages?.content?.length ? (
                    articlePages.content.map((article) => (
                      <ArticleCard
                        key={article.id}
                        article={article}
                        searchQuery={searchQuery}
                      />
                    ))
                  ) : (
                    <Typography>No Articles Found</Typography>
                  )}
                  {articlePages.totalPages > 1 && (
                    <Pagination
                      itemsPerPage={articlePages.pageable.pageSize}
                      totalItems={articlePages.totalElements}
                      currentPage={articlePages.pageable.pageNumber + 1}
                      paginate={(page) =>
                        fetchSearchResults(
                          page,
                          decisionPages.pageable.pageNumber + 1,
                          guideContentPages.pageable.pageNumber + 1
                        )
                      }
                    />
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='decision-content'
                  id='decision-header'
                >
                  <Typography variant='h6'>Decisions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* {decisionPages.results.map((decision, index) => (
                <DecisionCard key={index} decision={decision} />
              ))}
              <Pagination
                currentPage={currentDecisionPage}
                totalPages={decisionPages.totalPages}
                onPageChange={setCurrentDecisionPage}
              /> */}
                  {decisionPages?.content?.length ? (
                    decisionPages.content.map((decision) => (
                      <DecisionCard
                        key={decision.id}
                        article={decision}
                        searchQuery={searchQuery}
                      />
                    ))
                  ) : (
                    <Typography>No Decisions Found</Typography>
                  )}
                  {decisionPages.totalPages > 1 && (
                    <Pagination
                      itemsPerPage={decisionPages.pageable.pageSize}
                      totalItems={decisionPages.totalElements}
                      currentPage={decisionPages.pageable.pageNumber + 1}
                      paginate={(page) =>
                        fetchSearchResults(
                          articlePages.pageable.pageNumber + 1,
                          page,
                          guideContentPages.pageable.pageNumber + 1
                        )
                      }
                    />
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='decision-content'
                  id='guide-header'
                >
                  <Typography variant='h6'>Guides</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* {guideContentPages.results.map((guide, index) => (
                <GuideCard key={index} guide={guide} />
              ))}
              <Pagination
                currentPage={currentGuideContentPage}
                totalPages={guideContentPages.totalPages}
                onPageChange={setCurrentGuideContentPage}
              /> */}
                  {guideContentPages?.content?.length ? (
                    guideContentPages.content.map((guide) => (
                      <GuideCard
                        key={guide.id}
                        article={guide}
                        searchQuery={searchQuery}
                      />
                    ))
                  ) : (
                    <Typography>No Guides Found</Typography>
                  )}
                  {guideContentPages.totalPages > 1 && (
                    <Pagination
                      itemsPerPage={guideContentPages.pageable.pageSize}
                      totalItems={guideContentPages.totalElements}
                      currentPage={guideContentPages.pageable.pageNumber + 1}
                      paginate={(page) =>
                        fetchSearchResults(
                          articlePages.pageable.pageNumber + 1,
                          decisionPages.pageable.pageNumber + 1,
                          page
                        )
                      }
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Container>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen || isFetching}
            onClose={handleSnackbarClose}
            message='We are getting your requested data from backend'
            autoHideDuration={6000}
          />
        </Grid>
        {/* </Box> */}
      </Grid>
    </Container>
  )
}

export default GlobalSearch
